@import "tailwindcss/base";
@import "tailwindcss/components";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~filepond/dist/filepond.min.css';
@import '~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
@import "tailwindcss/utilities";

@layer components {
    .expanded-link {
        @apply after:content-[''] after:absolute after:w-full after:h-full after:top-0 after:left-0;
    }
}
